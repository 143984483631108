<template>
  <div class="notice_list_wrap">
    <div class="notice_list" v-for="(data, index) in boardData" :key="`faq${index}`"
         @click="moveDetail(`${data.wr_id}`)">
      <button>
        <div class="notice_title">{{index+1}}</div>
        <div class="notice_content">{{ data.Category.bc_name }}</div>
        <div class="notice_date">{{ data.wr_subject }}</div>
      </button>
    </div>
  </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import EventBus from "@/utils/event-bus";

export default {
  name: "BoardListFaqLayout",
  mixins: [alertMixins],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
    boardData: {default: () => {}},
  },
  data() {
    return{
      idx: 1,
    }
  },
  beforeRouterEnter() {},
  created() {

  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    moveDetail(data){
      this.$router.push(`/faq/${data}`)
    },
    tableIdx(index) {
      return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
    },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>